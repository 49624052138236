import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { User } from '@angular/fire/auth';
import { AuthService } from '@jfw-library/ecommerce/core';
import { ShippingAddress } from 'common-types';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
enum UpsResponseStatus {
  Uncategorized = 'Uncategorized',
  Success = 'Success',
  NoCandidate = 'NoCandidate',
  ServerFailure = 'ServerFailure',
}

export type UpsAddressType = {
  address1: string;
  city: string;
  state: string;
  zip: string;
  skipped: boolean;
};

export type UPSResponseType = {
  candidateMatches: boolean;
  status: UpsResponseStatus;
  message: string;
  candidates: UpsAddressType[];
};

export type UPSValidationPayload = {
  originalAddress: ShippingAddress;
  upsResponse: UPSResponseType;
  totalAddresses: number;
  addressNumber: number;
};

export type UPSRequest = {
  address1: string;
  city: string;
  state: string;
  zip: string;
};

@Injectable({
  providedIn: 'root',
})
export class UPSApiService {
  private UPS_REST_API_SERVER = environment.ups_rest_api_server_url;

  user: User | null = null;

  private reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private authService = inject(AuthService);

  constructor(private httpClient: HttpClient) {
    this.authService.authState$.subscribe((user) => {
      this.user = user;
    });
  }

  // public validateAddress(address: ShippingAddress): Promise<UPSResponseType> {
  //   console.log('VALIDATING ADDRESS API!!');
  //   const apiUrl = `${this.UPS_REST_API_SERVER}/validateAddress`;

  //   console.log(apiUrl);

  //   const requestData: UPSRequest = {
  //     address1: address.streetAddress1,
  //     city: address.city,
  //     state: address.state,
  //     zip: address.zip,
  //   };

  //   return firstValueFrom<UPSResponseType>(
  //     this.httpClient.post<UPSResponseType>(apiUrl, requestData, {
  //       headers: this.reqHeader,
  //     })
  //   )
  // }

  public async validateAddress(
    address: ShippingAddress,
  ): Promise<UPSResponseType | undefined> {
    try {
      console.log('VALIDATING ADDRESS API!!');
      const apiUrl = `${this.UPS_REST_API_SERVER}/validateAddress`;
      console.log(apiUrl);

      const requestData: UPSRequest = {
        address1: address.streetAddress1,
        city: address.city,
        state: address.state,
        zip: address.zip,
      };

      const response = await firstValueFrom<UPSResponseType>(
        this.httpClient.post<UPSResponseType>(apiUrl, requestData, {
          headers: this.reqHeader,
        }),
      );

      return response;
    } catch (error) {
      // Handle the error here
      console.log(
        '%c An error occurred during address validation: ',
        'background: #222; color: #bada55',
      );
      //throw error; // Rethrow the error if necessary
      return undefined;
    }
  }
}
